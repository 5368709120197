import type { Redirects } from "~/models/strapi/Redirect";

export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig();
  const { data } = await useFetch<{ data: Redirects }>(
    `${config.public.baseURL}/api/redirects`,
    {
      headers: {
        Authorization: `bearer ${config.strapiTokenApi}`,
      },
    },
  );
  const redirections = data.value?.data ?? [];
  const route: string = to.fullPath;

  for (const redirection of redirections) {
    if (route === redirection.attributes.from) {
      return redirection.attributes.type === "permanent"
        ? navigateTo(redirection.attributes.to, { redirectCode: 301 })
        : navigateTo(redirection.attributes.to, { redirectCode: 302 });
    }
  }
});
